import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";
import { useIntl } from "react-intl";
import { SessionContext } from "../contexts/sessionContext.js";
import {
  sendHxg,
  createPayment,
  showSessionContent,
  getSession,
  webCreditCount,
} from "../services/apiRequest.js";
import TelegramCode from "../pages/TelegramCode.js";
import "../styles/ChatProcess.scss";
import CheckoutForm from "./CheckoutForm.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import userLog from "../utils/userLog.js";

const ChatProcess = (props) => {
  const { showHandler, LANG, STYLE, QUESTION } = props;
  const cssStyleNameList = {
    1: "ChatProcess",
    2: "ChatProcess2",
    3: "ChatProcess3",
  };
  const cssStyleName = cssStyleNameList[STYLE];
  const { userSign, browserFingerPrint } = useContext(SessionContext);
  const intl = useIntl();
  const abortController = useRef(new AbortController());
  const [inputDisabled, setInputDisabled] = useState(true);
  const [showTelegram, setShowTelegram] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [remindCreditsCount, setRemindCredits] = useState(0);
  const [showPay, setShowPay] = useState(false);
  const [showCloseDialog, setShowCloseDialog] = useState(false);
  const defaultTimeout = 500;
  const extraTimeout = 100;
  const dollar = "USD$2";
  const navigate = useNavigate();

  //const telegram_t_codeMap = { en: "t_code_en", ch: "t_code_tc" };
  //const telegram_redeem_codeMap1 = { en: "redeem1_en", ch: "redeem1_tc" };
  //const telegram_redeem_codeMap2 = { en: "redeem2_en", ch: "redeem2_tc" };

  //show classify or not

  const isShowClassifyButton = false;

  const classifyMap = {
    love: intl.formatMessage({ id: "ChatProcess.love" }),
    wealth: intl.formatMessage({ id: "ChatProcess.wealth" }),
    career: intl.formatMessage({ id: "ChatProcess.career" }),
    family: intl.formatMessage({ id: "ChatProcess.family" }),
    studies: intl.formatMessage({ id: "ChatProcess.studies" }),
    health: intl.formatMessage({ id: "ChatProcess.health" }),
    lostItems: intl.formatMessage({ id: "ChatProcess.lostItems" }),
    judgement: intl.formatMessage({ id: "ChatProcess.judgement" }),
    others: intl.formatMessage({ id: "ChatProcess.others" }),
  };
  const genderMap = {
    M: intl.formatMessage({ id: "ChatProcess.male" }),
    F: intl.formatMessage({ id: "ChatProcess.female" }),
  };
  const resultMap = {
    6: intl.formatMessage({ id: "ChatProcess.greaterYin" }),
    7: intl.formatMessage({ id: "ChatProcess.lesserYang" }),
    8: intl.formatMessage({ id: "ChatProcess.lesserYin" }),
    9: intl.formatMessage({ id: "ChatProcess.greaterYang" }),
  };

  const langMap = { en: "en", "zh-TW": "ch" };
  const coinsMap = {
    "000": "threeHeads",
    "001": "twoHeadsOneTail",
    "010": "oneHeadOneTailOneHead",
    "011": "oneHeadTwoTails",
    100: "oneTailTwoHeads",
    101: "oneTailOneHeadOneTail",
    110: "twoTailsOneHead",
    111: "threeTails",
  };

  const rDictMap = {
    "000": "6",
    "001": "7",
    "010": "7",
    "011": "8",
    100: "7",
    101: "8",
    110: "8",
    111: "9",
  };

  //object ids
  const divMethodButtonsId = "DivMethodButtons";
  const inputFrameId = "U_InputFrame";
  const inputTextId = "U_InputText";
  const classifyButtonsId = "ClassifyButtons";
  const GenderButtonsId = "GenderButtons";
  const unlockButtonId = "UnlockButton";
  const selfTossButtonId = "SelfTossButtons";
  const onlineTossButtonId = "OnlineTossButton";
  const interactiveButtonsId = "InteractiveButtons";
  const changeQuestionButtonsId = "ChangeQuestionButtons";
  const telegramTrialButtonId = "TelegramTrialButton";
  const retryUnlockButtonId = "RetryUnlockButton";
  const retryButtonId = "RetryButton";
  const CreditButtonsId = "CreditButton";

  let webSessionId = useRef(null);
  let userQuestion = useRef(null);
  let userhexagram = useRef(null);
  let classify = useRef(null);
  let status = useRef(0); // use in handleSend 0: ask first question, 1: user answer question
  let gender = useRef(null);
  let classifyResult = useRef(null);
  let threeCoinsResult = useRef(null);
  // to change the default interactive time and change qustion time, need to modify the api
  let remainInteractiveTime = useRef(-1);
  let remainChangeQuestionTime = useRef(-1);
  let userAnsQ = useRef(0);
  let chatRecords = useRef(null);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const changeShowPay = () => {
    setShowPay(false);
  };

  const toss3Coins = () => {
    const coninArr = ["1", "0"];
    let tempResult = "";
    for (let j = 0; j < 3; j++) {
      const tempRndChar = coninArr[Math.floor(Math.random() * coninArr.length)];
      tempResult += tempRndChar;
    }
    threeCoinsResult.current = tempResult;
  };

  const appendUserHex = (e, fnParas) => {
    userhexagram.current = userhexagram.current
      ? (userhexagram.current += fnParas.line)
      : fnParas.line;
    handleSelfToss();
  };

  const appendOnlineHex = (e) => {
    toss3Coins();
    userhexagram.current = userhexagram.current
      ? (userhexagram.current += rDictMap[threeCoinsResult.current])
      : rDictMap[threeCoinsResult.current];
    handleOnlineToss();
  };

  const removeTarget = (objId) => {
    try {
      document.getElementById(objId).remove();
    } catch {}
  };

  const genRandomHex = () => {
    let result = "";
    for (let i = 0; i < 6; i++) {
      toss3Coins();
      result += rDictMap[threeCoinsResult.current];
    }
    return result;
  };

  const handleGenderAction = () => {
    removeTarget(GenderButtonsId);
    if (gender.current) {
      showBotsText(
        `${intl.formatMessage({ id: "ChatProcess.gender" })}${
          genderMap[gender.current]
        }`
      );
    } else {
      showBotsText(
        `${intl.formatMessage({
          id: "ChatProcess.gender",
        })}${intl.formatMessage({ id: "ChatProcess.unwillingToDisclose" })}`
      );
    }
    webCreditCount(
      langMap[LANG],
      { agentId: browserFingerPrint, append: true },
      abortController.current,
      async (error, resQ) => {
        if (error) {
          showBotsText(error);
        } else {
          if (resQ) {
            const remainCredits = resQ.credit.total;
            const remainFreeCredits = resQ.credit.freeCredits;
            setRemindCredits(remainFreeCredits);
            if (remainCredits > 0) {
              if (remainFreeCredits <= 0) {
                showCreditButtons(remainCredits);
              } else {
                handleUseCredit();
              }
            }
          }
        }
      }
    );

    //showBotsText(intl.formatMessage({ id: "ChatProcess.enterQuestion" }));
    //setInputDisabled(false);
    //setFocusToTextBox();
  };

  const handleSend = (e, userText = null) => {
    removeTarget(retryButtonId);
    const inputTextBox = document.getElementById(inputTextId);
    let userQ = "";
    if (userText === null) {
      userQ = inputTextBox.value.trim();
    } else {
      userQ = userText.userText;
    }

    if (userQ.length > 0) {
      setInputDisabled(true);
      userQuestion.current = userQ;
      if (inputTextBox) {
        if (inputTextBox.value) showYourText(inputTextBox.value);
        inputTextBox.value = "";
      }
      if (status.current === 0) {
        showWaitMessage();
        const postData = {
          question: userQuestion.current,
          language: langMap[LANG],
          questionType: classify.current,
          agentId: browserFingerPrint,
        };
        if (gender.current) postData.gender = gender.current;
        sendHxg(
          langMap[LANG],
          postData,
          abortController.current,
          (err, res) => {
            if (err) {
              showRetry(handleSend, { userText: userQ });
            } else {
              if (res.passed) {
                classifyResult.current = res;

                setTimeout(() => {
                  /* showBotsText(
                    intl.formatMessage({ id: "ChatProcess.cleanYourMind" })
                  ); */
                  setTimeout(() => {
                    showBotsText(
                      intl.formatMessage({ id: "ChatProcess.imagineQuestion" })
                    );
                    setTimeout(() => {
                      showDivMethodsButtons();
                      setInputDisabled(true);
                    }, 1000);
                  }, 1000);
                }, 1000);
              } else {
                showBotsText(res.content);
                setInputDisabled(false);
              }
            }
          }
        );
      } else if (status.current === 1) {
        showWaitMessage();
        removeTarget(changeQuestionButtonsId);
        const postData = {
          question: userQuestion.current,
          language: langMap[LANG],
          userhexagram: userhexagram.current,
          sessionId: webSessionId.current,
          userInterQuestion: 1,
          questionType: classify.current,
          agentId: browserFingerPrint,
        };
        if (gender.current) postData.gender = gender.current;
        if (userAnsQ.current === 1) {
          postData.userAnsQ = 1;
        } else {
          postData["isotime"] = new Date().valueOf();
          postData["uHash"] = userSign;
        }
        sendHxg(
          langMap[LANG],
          postData,
          abortController.current,
          (err, res) => {
            let endDivAns = "";
            if (err) {
              endDivAns = err;
              showRetry(handleSend, { userText: userQ });
            } else {
              if (res.aiAnswer) {
                endDivAns = res.aiAnswer;
                remainInteractiveTime.current = res.remainInteractive;
                remainChangeQuestionTime.current = res.remainChangeQuestion;
                if (remainInteractiveTime.current > 0)
                  showBotsText(res.aiAnswer);
              } else {
                endDivAns = "Unknown error";
              }
            }
            if (remainInteractiveTime.current > 0) {
              showInteractiveButtons();
            } else {
              showBotsText(endDivAns);
              endDivConclusion();
            }
          }
        );
      }
    }
  };

  const handleClose = () => {
    //showHandler(false);
    setShowCloseDialog(true);
  };

  const closeReturn = () => {
    //navigate("/chat");
    setShowCloseDialog(false);
  };

  const confirmClose = () => {
    navigate(-1);
    showHandler(false);
  };

  const getSolusion = (cb) => {
    const postData = {
      question: intl.formatMessage({ id: "ChatProcess.solution" }),
      userhexagram: userhexagram.current,
      language: langMap[LANG],
      questionType: classify.current,
      agentId: browserFingerPrint,
      isEnd: true,
      sessionId: webSessionId.current,
    };
    sendHxg(langMap[LANG], postData, abortController.current, (err, res) => {
      if (res) {
        showBotsText(res.aiAnswer);
        cb();
      }
    });
  };

  const endDivConclusion = () => {
    removeTarget(interactiveButtonsId);
    removeTarget(changeQuestionButtonsId);
    removeTarget(inputFrameId);

    setInputDisabled(true);
    if (STYLE === "1") {
      showBotsText(intl.formatMessage({ id: "ChatProcess.thankYou" }));
    } else {
      getSolusion(() => {
        setTimeout(() => {
          /* showBotsText(
            intl.formatMessage({ id: "ChatProcess.telegramLinkExplain" })
          );
          showPic(`${process.env.PUBLIC_URL}/imgs/telegramDemo1.jpg`);
          const btnDiv = document.createElement("div");
          btnDiv.className = "ChatProcess-interactiveFrame_btnFrame";
          const sBtn = makeTextEle(
            intl.formatMessage({
              id: "ChatProcess.divinationOnTelegram",
            }),
            null
          );
          sBtn.style.width = "100%";
          sBtn.className = "ChatProcess-interactiveFrame_button";
          sBtn.firstChild.className = "ChatProcess-telegramBtn";
          sBtn.firstChild.onclick = divinationOnTelegram;
          btnDiv.appendChild(sBtn);
          const lineFrameEle = makeLineFrame(btnDiv);
          appendContent(lineFrameEle); */
          setTimeout(() => {
            if (STYLE === "2") {
              showPic(`${process.env.REACT_APP_IMG_URL2}web_img/mirror02.jpg`);
            } else if (STYLE === "3") {
              showPic(`${process.env.PUBLIC_URL}/imgs/chat03.png`);
            }
          }, 3000);
        }, 0);
      });
    }
  };

  const divinationOnTelegram = () => {
    window.open(
      "https://t.me/MirrorcleBot?start=giftcodehJ5FH-JFRvH-HEJRX-kL6kG",
      "_blank"
    );
  };

  const setClassify = (e, paras) => {
    classify.current = paras.userClassify;
    removeTarget(classifyButtonsId);
    showBotsText(
      `${intl.formatMessage({
        id: "ChatProcess.categorySelected",
      })}${classifyMap[classify.current]}`
    );
    if (paras.userClassify === "love") {
      showGenderButtons();
    } else {
      //calculate credits
      webCreditCount(
        langMap[LANG],
        { agentId: browserFingerPrint, append: true },
        abortController.current,
        async (error, resQ) => {
          if (error) {
            showBotsText(error);
          } else {
            if (resQ) {
              const remainCredits = resQ.credit.total;
              const remainFreeCredits = resQ.credit.freeCredits;
              setRemindCredits(remainFreeCredits);
              if (remainCredits > 0) {
                if (remainFreeCredits <= 0) {
                  showCreditButtons(remainCredits);
                } else {
                  handleUseCredit();
                }
              }
            }
          }
        }
      );
    }
  };

  const setFocusToTextBox = () => {
    document.getElementById(inputTextId).focus();
  };

  const setGenderMale = (e) => {
    gender.current = "M";
    handleGenderAction();
  };

  const setGenderFemale = (e) => {
    gender.current = "F";
    handleGenderAction();
  };

  const setGenderNull = (e) => {
    gender.current = null;
    handleGenderAction();
  };

  const appendContent = (ele) => {
    const uInteractive = document.getElementById("u_interactive");
    if (uInteractive) {
      const children = document.getElementById("u_interactive").children;
      const childrenArray = Array.from(children);
      if (childrenArray.length < 1) {
        document.getElementById("u_interactive").appendChild(ele);
      } else {
        const firstNode = document.getElementById("u_interactive").firstChild;
        document.getElementById("u_interactive").insertBefore(ele, firstNode);
      }
    }
  };

  const makeLineFrame = (ele) => {
    const lineDiv = document.createElement("div");
    lineDiv.className = "ChatProcess-interactiveFrame_lineFrame";
    lineDiv.appendChild(ele);
    return lineDiv;
  };

  const makeTextEle = (text, cusClassName) => {
    const textContent = document.createTextNode(text);
    const textDiv = document.createElement("div");
    textDiv.appendChild(textContent);
    if (cusClassName) {
      textDiv.className = cusClassName;
    } else {
    }
    const lineFrameEle = makeLineFrame(textDiv);
    return lineFrameEle;
  };

  const makeButtons = (
    buttonsHeaderText,
    numOfButtonsPerRow,
    cusClassName,
    btnMaps
  ) => {
    //format: btnMaps = Array[{btnText: "text", fn: function(), fnParas: { userClassify: "wealth" }}]
    const numOfRows = parseInt(btnMaps.length / numOfButtonsPerRow);
    const numOfRemainBtns = btnMaps.length % numOfButtonsPerRow;
    const btnDiv = document.createElement("div");

    if (cusClassName) {
      btnDiv.className = cusClassName;
    } else {
      btnDiv.className = "ChatProcess-interactiveFrame_btnFrame";
    }
    const childBtnWidth = (100 / numOfButtonsPerRow).toString() + "%";

    for (let i = 0; i < btnMaps.length; i++) {
      const sBtn = makeTextEle(btnMaps[i]["btnText"], null);
      sBtn.className = "ChatProcess-interactiveFrame_button";
      sBtn.firstChild.className = `${cssStyleName}-interactiveFrame_innerButton`;

      if (numOfRemainBtns === 0 || i + 1 <= numOfRows * numOfButtonsPerRow) {
        sBtn.style.width = childBtnWidth;
      } else {
        sBtn.style.width = (100 / numOfRemainBtns).toString() + "%";
      }
      if (!btnMaps[i].fnParas) {
        sBtn.firstChild.onclick = btnMaps[i].fn;
      } else {
        sBtn.firstChild.onclick = (e) => {
          btnMaps[i].fn(e, btnMaps[i].fnParas);
        };
      }
      btnDiv.appendChild(sBtn);
    }
    const buttonsHeader = makeTextEle(
      buttonsHeaderText,
      `${cssStyleName}interactiveFrame_botTextObj`
    );
    const headerLine = makeLineFrame(buttonsHeader);
    headerLine.style.display = "flex";
    headerLine.style.flexDirection = "column";
    const lineFrameEle = makeLineFrame(btnDiv);
    headerLine.appendChild(lineFrameEle);
    return headerLine;
  };

  const showRetry = async (fn, fnParas) => {
    const errMsg = intl.formatMessage({ id: "ResultBox.retry" });
    let btnMaps = null;
    if (!fnParas) {
      btnMaps = [
        {
          btnText: intl.formatMessage({
            id: "ChatProcess.retry",
          }),
          fn: fn,
        },
      ];
    } else {
      btnMaps = [
        {
          btnText: intl.formatMessage({
            id: "ChatProcess.retry",
          }),
          fn: fn,
          fnParas: fnParas,
        },
      ];
    }
    const retryButtons = makeButtons(errMsg, 1, null, btnMaps);
    retryButtons.id = retryButtonId;
    appendContent(retryButtons);
  };

  const showBotsText = async (text) => {
    const appendText = makeTextEle(
      text,
      `${cssStyleName}-interactiveFrame_botTextObj`
    );
    appendContent(appendText);
  };

  const showWaitMessage = () => {
    const waitText = makeTextEle(
      `${intl.formatMessage({
        id: "ChatProcess.pleaseWait",
      })}`,
      `${cssStyleName}-interactiveFrame_botTextObj`
    );
    appendContent(waitText);
  };

  const showYourText = (text) => {
    const appendText = makeTextEle(
      text,
      `${cssStyleName}-interactiveFrame_yourTextObj`
    );
    appendContent(appendText);
  };

  const showClassifyButtons = () => {
    const classifyBtns = makeButtons(
      intl.formatMessage({ id: "ChatProcess.selectClass" }),
      3,
      null,
      [
        {
          btnText: intl.formatMessage({
            id: "ChatProcess.love",
          }),
          fn: handleGenderButtons,
        },
        {
          btnText: intl.formatMessage({ id: "ChatProcess.wealth" }),
          fn: setClassify,
          fnParas: { userClassify: "wealth" },
        },
        {
          btnText: intl.formatMessage({ id: "ChatProcess.career" }),
          fn: setClassify,
          fnParas: { userClassify: "career" },
        },
        {
          btnText: intl.formatMessage({ id: "ChatProcess.family" }),
          fn: setClassify,
          fnParas: { userClassify: "family" },
        },
        {
          btnText: intl.formatMessage({ id: "ChatProcess.studies" }),
          fn: setClassify,
          fnParas: { userClassify: "studies" },
        },
        {
          btnText: intl.formatMessage({ id: "ChatProcess.health" }),
          fn: setClassify,
          fnParas: { userClassify: "health" },
        },
        {
          btnText: intl.formatMessage({ id: "ChatProcess.lostItems" }),
          fn: setClassify,
          fnParas: { userClassify: "lostItems" },
        },
        {
          btnText: intl.formatMessage({ id: "ChatProcess.judgement" }),
          fn: setClassify,
          fnParas: { userClassify: "judgement" },
        },
        {
          btnText: intl.formatMessage({ id: "ChatProcess.others" }),
          fn: setClassify,
          fnParas: { userClassify: "others" },
        },
      ]
    );
    classifyBtns.id = classifyButtonsId;
    setTimeout(() => {
      appendContent(classifyBtns);
    }, defaultTimeout);
  };

  const showCreditButtons = (remainCredits) => {
    const creditButtons = makeButtons(
      intl.formatMessage({ id: "ChatProcess.useCredit" }, { remainCredits }),
      2,
      null,
      [
        {
          btnText: intl.formatMessage({
            id: "ChatProcess.yes",
          }),
          fn: handleUseCredit,
        },
        {
          btnText: intl.formatMessage({
            id: "ChatProcess.no",
          }),
          fn: handleNotUseCredit,
        },
      ]
    );
    creditButtons.id = CreditButtonsId;
    setTimeout(() => {
      appendContent(creditButtons);
    }, defaultTimeout);
  };

  const showGenderButtons = (e) => {
    const genderButtons = makeButtons(
      intl.formatMessage({ id: "ChatProcess.tellMeGender" }),
      2,
      null,
      [
        {
          btnText: intl.formatMessage({
            id: "ChatProcess.male",
          }),
          fn: setGenderMale,
        },
        {
          btnText: intl.formatMessage({
            id: "ChatProcess.female",
          }),
          fn: setGenderFemale,
        },
        {
          btnText: intl.formatMessage({
            id: "ChatProcess.unwillingToDisclose",
          }),
          fn: setGenderNull,
        },
      ]
    );
    genderButtons.id = GenderButtonsId;
    setTimeout(() => {
      appendContent(genderButtons);
    }, defaultTimeout);
  };

  const showSelfTossButtons = (infoText) => {
    const btnMaps = [
      {
        btnText: intl.formatMessage({
          id: "ChatProcess.3tails",
        }),
        fn: appendUserHex,
        fnParas: { line: "9" },
      },
      {
        btnText: intl.formatMessage({ id: "ChatProcess.2head1tails" }),
        fn: appendUserHex,
        fnParas: { line: "7" },
      },
      {
        btnText: intl.formatMessage({ id: "ChatProcess.3head" }),
        fn: appendUserHex,
        fnParas: { line: "6" },
      },
      {
        btnText: intl.formatMessage({ id: "ChatProcess.2tails1head" }),
        fn: appendUserHex,
        fnParas: { line: "8" },
      },
    ];
    const selfTossButtons = makeButtons(infoText, 2, null, btnMaps);
    selfTossButtons.id = selfTossButtonId;
    appendContent(selfTossButtons);
  };

  const showOnlineTossButton = (infoText) => {
    removeTarget(onlineTossButtonId);
    const onlineTossButton = document.createElement("div");
    onlineTossButton.className = `${cssStyleName}-interactiveFrame_botTextObj`;
    const tossImg = document.createElement("img");
    if (!userhexagram.current) {
      //tossImg.src = `${process.env.PUBLIC_URL}/imgs/flip_coin.gif`;
      tossImg.src = `${process.env.REACT_APP_IMG_URL}web_img/flip_coin.gif`;
    } else {
      const preResult = coinsMap[threeCoinsResult.current];
      //tossImg.src = `${process.env.PUBLIC_URL}/imgs/${preResult}.jpg`;
      tossImg.src =
        STYLE === "1"
          ? `${process.env.REACT_APP_IMG_URL}web_img/${preResult}.jpg`
          : `${process.env.REACT_APP_IMG_URL2}web_img/${preResult}.jpg`;
    }
    tossImg.className = "ChatProcess-interactiveFrame_tossImg";
    const statusText = makeTextEle(infoText, null);
    const innerOnlineTossButton = makeTextEle(
      intl.formatMessage({ id: "ChatProcess.toss" }),
      null
    );
    innerOnlineTossButton.className =
      "ChatProcess-interactiveFrame_innerButton";
    innerOnlineTossButton.onclick = appendOnlineHex;
    onlineTossButton.appendChild(tossImg);
    onlineTossButton.appendChild(statusText);
    if (!userhexagram.current) {
      onlineTossButton.appendChild(innerOnlineTossButton);
    } else {
      if (userhexagram.current.length < 6) {
        onlineTossButton.appendChild(innerOnlineTossButton);
      }
    }
    const lineFrame = makeLineFrame(onlineTossButton);
    onlineTossButton.id = onlineTossButtonId;
    appendContent(lineFrame);
  };

  const showChangeQuestionButtons = () => {
    const infoText = `${intl.formatMessage({
      id: "ChatProcess.changeQuestionMsg",
    })}`;
    const btnMaps = [
      {
        btnText: intl.formatMessage({
          id: "ChatProcess.endDivination",
        }),
        fn: endDivConclusion,
      },
      {
        btnText: intl.formatMessage({ id: "ChatProcess.changeQuestion" }),
        fn: handleChangeQuestion,
      },
    ];
    const changeQuestionButtons = makeButtons(infoText, 1, null, btnMaps);
    changeQuestionButtons.id = changeQuestionButtonsId;
    appendContent(changeQuestionButtons);
  };

  const updateSessionContent = (cb) => {
    const postData = { sessionId: webSessionId.current };
    showSessionContent(
      langMap[LANG],
      postData,
      abortController.current,
      (err, res) => {
        if (err) {
          cb(err);
        } else {
          chatRecords.current = res.messages;
          remainChangeQuestionTime.current = res.remainChangeQuestionTime;
          remainInteractiveTime.current = res.remainInteractiveTime;
          cb();
        }
      }
    );
  };

  const handlePay = (e) => {
    const postData = { sessionId: webSessionId.current };
    createPayment(
      langMap[LANG],
      postData,
      abortController.current,
      (err, res) => {
        if (err) {
          showBotsText(err);
        } else {
          setClientSecret(res.client_secret);
          setShowPay(true);
        }
      }
    );
    //for testing
    //handleSuccessPay();
  };

  const handleSuccessPay = () => {
    removeTarget(unlockButtonId);
    removeTarget(retryUnlockButtonId);
    showBotsText(intl.formatMessage({ id: "ChatProcess.paymentSuccessful" }));
    showWaitMessage();
    setTimeout(() => {
      updateSessionContent((err) => {
        if (err) {
          showBotsText(err);
        } else {
          if (!chatRecords.current) {
            const btnMaps = [
              {
                btnText: intl.formatMessage({ id: "ChatProcess.retry" }),
                fn: handleSuccessPay,
              },
            ];
            const retryUnlockButtons = makeButtons(
              intl.formatMessage({ id: "ChatProcess.unlockRetry" }),
              1,
              null,
              btnMaps
            );
            retryUnlockButtons.id = retryUnlockButtonId;
            appendContent(retryUnlockButtons);
          } else {
            try {
              const fullAns =
                chatRecords.current[chatRecords.current.length - 1].choices[0]
                  .message.content;
              showBotsText(fullAns);
              handleInteractive();
            } catch (e) {
              showBotsText(e.toString());
            }
          }
        }
      });
    }, defaultTimeout + 5 * extraTimeout);
  };

  const handleInteractive = () => {
    showInteractiveButtons();
  };

  const handleGenderButtons = (e) => {
    setClassify(e, { userClassify: "love" });
  };

  const handleUseCredit = () => {
    removeTarget(CreditButtonsId);
    if (QUESTION === null) {
      showBotsText(intl.formatMessage({ id: "ChatProcess.enterQuestion" }));
      setInputDisabled(false);
      setFocusToTextBox();
    } else {
      handleSend(null, { userText: QUESTION.q });
    }
  };

  const handleNotUseCredit = () => {
    handleClose();
  };

  const handleDivination = () => {
    const locPicPath = LANG === "en" ? "iching_div_jp" : "iching_div_ch";
    const divinationUrl =
      STYLE === "2"
        ? `${process.env.REACT_APP_IMG_URL2}`
        : `${process.env.REACT_APP_IMG_URL}`;
    removeTarget(divMethodButtonsId);
    setTimeout(() => {
      showPic(`${divinationUrl}${locPicPath}/${userhexagram.current}.jpg`);
      showBotsText(intl.formatMessage({ id: "ChatProcess.firstHxg" }));

      const postData = {
        classifyResult: classifyResult.current,
        userhexagram: userhexagram.current,
        question: userQuestion.current,
        language: langMap[LANG],
        isotime: new Date().valueOf(),
        uHash: userSign,
        sessionId: webSessionId.current,
        agentId: browserFingerPrint,
        questionType: classify.current,
      };
      if (gender.current) postData.gender = gender.current;
      setTimeout(() => {
        sendHxg(
          langMap[LANG],
          postData,
          abortController.current,
          async (err, res) => {
            if (err) {
              showBotsText(err);
            } else {
              try {
                if (res) {
                  updateCreditDisplay();
                  if (res.shortAns === 1) {
                    //showBotsText(intl.formatMessage(`ChatProcess.noCredit`));
                    showBotsText(res.answer.choices[0].message.content);
                    showUnlockButtons();
                    showTelegramLoginButtons();
                  } else {
                    showBotsText(res.answer.choices[0].message.content);
                    remainInteractiveTime.current = res.remainInteractive;
                    remainChangeQuestionTime.current = res.remainChangeQuestion;
                    handleInteractive();
                  }
                }
              } catch (e) {
                showBotsText(e.toString());
              }
            }
          }
        );
      }, 5000);
    }, 5000);
  };

  const showPic = (imgUrl) => {
    const container = document.createElement("div");
    container.className =
      STYLE === "1"
        ? "ChatProcess-interactiveFrame_botImgObj"
        : "ChatProcess-interactiveFrame_botImgObj2";
    const imgBox = document.createElement("img");
    imgBox.onclick = () => {
      const closeZoomInBtn = document.createElement("div");
      const closeText = document.createTextNode(
        intl.formatMessage({ id: "ChatProcess.closeText" })
      );
      closeZoomInBtn.appendChild(closeText);
      closeZoomInBtn.className = "ChatProcess-interactiveFrame_closeZoomIn";
      closeZoomInBtn.onclick = () => {
        removeTarget("ZoomIn");
      };
      const zoomImgBox = document.createElement("img");
      zoomImgBox.src = imgUrl;
      zoomImgBox.className = "ChatProcess-interactiveFrame_zoomInPic";
      const zoomContainer = document.createElement("div");
      zoomContainer.id = "ZoomIn";
      zoomContainer.className =
        STYLE === "1" ? "ChatProcess-bg" : "ChatProcess-imgFullBg";
      zoomContainer.appendChild(zoomImgBox);
      zoomContainer.appendChild(closeZoomInBtn);
      appendContent(zoomContainer);
    };
    container.appendChild(imgBox);
    imgBox.src = imgUrl;
    imgBox.className = "ChatProcess-interactiveFrame_hexImg";
    const lineFrame = makeLineFrame(container);
    appendContent(lineFrame);
  };

  const showDivMethodsButtons = () => {
    const coinTossBtns = makeButtons(
      `${intl.formatMessage({ id: "ChatProcess.selectDiviMethod" })}`,
      1,
      null,
      [
        /*  {
          btnText: intl.formatMessage({
            id: "ChatProcess.howToCoinToss",
          }),
          fn: showHowToCoinToss,
        }, */
        {
          btnText: intl.formatMessage({ id: "ChatProcess.coinTossRandom" }),
          fn: handleRandomToss,
        },
        {
          btnText: intl.formatMessage({ id: "ChatProcess.coinTossOnline" }),
          fn: handleOnlineToss,
        },
        {
          btnText: intl.formatMessage({ id: "ChatProcess.coinTossYourself" }),
          fn: handleSelfToss,
        },
      ]
    );
    coinTossBtns.id = divMethodButtonsId;
    appendContent(coinTossBtns);
  };

  const showUnlockButtons = (paid = false) => {
    let btnMaps = null;
    if (!paid) {
      btnMaps = [
        {
          btnText: intl.formatMessage({ id: "ChatProcess.payToUnlock" }),
          fn: handlePay,
        },
      ];
    } else {
      btnMaps = [
        {
          btnText: intl.formatMessage({ id: "ChatProcess.tryOnTelegram" }),
          fn: showTelegramLogin,
        },
      ];
    }
    const unlockButtons = makeButtons(
      intl.formatMessage({ id: "ChatProcess.unlockHints" }, { dollar }),
      1,
      null,
      btnMaps
    );
    unlockButtons.id = unlockButtonId;
    appendContent(unlockButtons);
  };

  const showTelegramLoginButtons = () => {
    const btnMaps = [
      {
        btnText: intl.formatMessage({ id: "ChatProcess.tryOnTelegram" }),
        fn: showTelegramLogin,
      },
    ];
    const telegramTrialButtons = makeButtons(
      intl.formatMessage({ id: "ChatProcess.telegramHints" }),
      1,
      null,
      btnMaps
    );
    telegramTrialButtons.id = telegramTrialButtonId;
    appendContent(telegramTrialButtons);
  };

  const showInteractiveButtons = () => {
    const tempTime = STYLE === "1" ? remainInteractiveTime.current : "";
    let infoText = `${intl.formatMessage({
      id: "ChatProcess.interactiveTimes",
    })}${tempTime}`;
    if (remainInteractiveTime.current <= 1 && LANG === "zh-TW") {
      infoText = `${intl.formatMessage({
        id: "ChatProcess.interactiveLastTimes",
      })}${tempTime}`;
    }

    const btnMaps = [
      {
        btnText: intl.formatMessage({ id: "ChatProcess.aiAsksYou" }),
        fn: handleAiAskYou,
        fnParas: { isChangeQuestionReq: false },
      },
      {
        btnText: intl.formatMessage({ id: "ChatProcess.youAskAi" }),
        fn: handleYouAskAi,
      },
      {
        btnText: intl.formatMessage({
          id: "ChatProcess.endDivination",
        }),
        fn: endDivConclusion,
      },
    ];
    const interactiveButtons = makeButtons(infoText, 1, null, btnMaps);
    interactiveButtons.id = interactiveButtonsId;
    appendContent(interactiveButtons);
  };

  const showTelegramLogin = () => {
    window.open(
      "https://t.me/MirrorcleBot?start=giftcodehJ5FH-JFRvH-HEJRX-kL6kG",
      "_blank"
    );
  };

  const showHowToCoinToss = (e) => {
    const divMethodButtons = document.getElementById(divMethodButtonsId);
    removeTarget(divMethodButtonsId);
    showBotsText(intl.formatMessage({ id: "ChatProcess.coinTossMethod" }));
    /*  setTimeout(() => {
      appendContent(divMethodButtons);
    }, defaultTimeout + 3 * extraTimeout); */
  };

  const handleSelfToss = () => {
    removeTarget(divMethodButtonsId);
    removeTarget(selfTossButtonId);
    if (!userhexagram.current) showHowToCoinToss();
    const Nth = userhexagram.current
      ? parseInt(userhexagram.current.length) + 1
      : 1;
    let preTossResult = "";
    if (userhexagram.current) {
      for (let kth = 1; kth <= userhexagram.current.length; kth++) {
        preTossResult += `\n${intl.formatMessage(
          { id: "ChatProcess.nthCoinToss" },
          { kth }
        )}${resultMap[userhexagram.current.charAt(kth - 1)]}`;
      }
    }

    let classifyText = "";
    if (isShowClassifyButton) {
      classifyText = `${intl.formatMessage({
        id: "ChatProcess.yourQuestionCategory",
      })}${classifyMap[classify.current]}\n`;
    }
    let genderText = "";
    if (classify.current === "love") {
      genderText = `${intl.formatMessage({ id: "ChatProcess.gender" })}${
        genderMap[gender.current] ||
        intl.formatMessage({ id: "ChatProcess.unwillingToDisclose" })
      }\n`;
    }
    let pleaseChooseText =
      !userhexagram.current || userhexagram.current.length < 6
        ? `\n${intl.formatMessage(
            {
              id: "ChatProcess.selectCoinTossResult",
            },
            { Nth }
          )}`
        : "";
    let infoText = `${intl.formatMessage({
      id: "ChatProcess.openBrackets",
    })}${intl.formatMessage({
      id: "ChatProcess.coinTossYourself",
    })}${intl.formatMessage({
      id: "ChatProcess.closeBrackets",
    })}\n${preTossResult}\n${pleaseChooseText}`;

    if (!userhexagram.current) {
      showSelfTossButtons(infoText);
    } else {
      if (userhexagram.current.length < 6) {
        showSelfTossButtons(infoText);
      } else {
        showBotsText(infoText);
        handleDivination();
      }
    }
  };

  const handleRandomToss = (e) => {
    userhexagram.current = genRandomHex();
    let classifyText = "";
    if (isShowClassifyButton) {
      classifyText = `${intl.formatMessage({
        id: "ChatProcess.yourQuestionCategory",
      })}${classifyMap[classify.current]}\n`;
    }
    let genderText = "";
    if (classify.current === "love") {
      genderText = `${intl.formatMessage({ id: "ChatProcess.gender" })}${
        genderMap[gender.current] ||
        intl.formatMessage({ id: "ChatProcess.unwillingToDisclose" })
      }\n`;
    }
    const randomTossText = `${intl.formatMessage({
      id: "ChatProcess.openBrackets",
    })}${intl.formatMessage({
      id: "ChatProcess.coinTossRandom",
    })}${intl.formatMessage({
      id: "ChatProcess.closeBrackets",
    })}\n${classifyText}${genderText}`;
    //showBotsText(randomTossText);
    handleDivination();
  };

  const handleOnlineToss = () => {
    removeTarget(divMethodButtonsId);
    removeTarget(onlineTossButtonId);
    if (!userhexagram.current) showHowToCoinToss();
    const Nth = userhexagram.current
      ? parseInt(userhexagram.current.length) + 1
      : 1;
    let preTossResult = "";
    if (userhexagram.current) {
      for (let kth = 1; kth <= userhexagram.current.length; kth++) {
        preTossResult += `\n${intl.formatMessage(
          { id: "ChatProcess.nthCoinToss" },
          { kth }
        )}${resultMap[userhexagram.current.charAt(kth - 1)]}`;
      }
    }
    let classifyText = "";
    if (isShowClassifyButton) {
      classifyText = `${intl.formatMessage({
        id: "ChatProcess.yourQuestionCategory",
      })}${classifyMap[classify.current]}\n`;
    }

    let genderText = "";
    if (classify.current === "love") {
      genderText = `${intl.formatMessage({ id: "ChatProcess.gender" })}${
        genderMap[gender.current] ||
        intl.formatMessage({ id: "ChatProcess.unwillingToDisclose" })
      }\n`;
    }
    let tossHintsText = "";
    if (userhexagram.current) {
      if (userhexagram.current.length === 6) {
        tossHintsText = "";
      }
    } else {
      tossHintsText = intl.formatMessage(
        {
          id: "ChatProcess.onlineCoinToss",
        },
        { Nth }
      );
    }

    let infoText = `${intl.formatMessage({
      id: "ChatProcess.openBrackets",
    })}${intl.formatMessage({
      id: "ChatProcess.coinTossOnline",
    })}${intl.formatMessage({
      id: "ChatProcess.closeBrackets",
    })}\n${classifyText}${genderText}${preTossResult}\n\n${tossHintsText}`;

    if (!userhexagram.current) {
      setTimeout(() => {
        showOnlineTossButton(infoText);
      }, 2000);
    } else {
      if (userhexagram.current.length <= 6) {
        if (Nth === 2 || Nth === 3 || Nth === 5) {
          infoText += `\n${intl.formatMessage(
            {
              id: "ChatProcess.onlineCoinSetToss",
            },
            { Nth }
          )}`;
        } else if (Nth === 4) {
          infoText += `\n${intl.formatMessage(
            {
              id: "ChatProcess.onlineCoinTossAgain",
            },
            { Nth }
          )}`;
        } else if (Nth === 6) {
          infoText += `\n${intl.formatMessage(
            {
              id: "ChatProcess.onlineCoinTossLast",
            },
            { Nth }
          )}`;
        }
        setTimeout(() => {
          showOnlineTossButton(infoText);
        }, 2000);
      }
      if (userhexagram.current.length === 6) {
        handleDivination();
      }
    }
  };

  const handleAiAskYou = (e, fnParas) => {
    const { isChangeQuestionReq } = fnParas;
    if (remainInteractiveTime.current > 0 || isChangeQuestionReq) {
      removeTarget(interactiveButtonsId);
      removeTarget(retryButtonId);
      showWaitMessage();
      //get ai question
      const postData = {
        question: userQuestion.current,
        aiInterQuestion: 1,
        agentId: browserFingerPrint,
        userhexagram: userhexagram.current,
        sessionId: webSessionId.current,
        language: langMap[LANG],
        questionType: classify.current,
        isotime: new Date().valueOf(),
        uHash: userSign,
      };

      if (gender.current) postData.gender = gender.current;
      if (isChangeQuestionReq) postData.changeQuestion = 1;

      sendHxg(langMap[LANG], postData, abortController.current, (err, res) => {
        if (err) {
          showRetry(handleAiAskYou, {
            isChangeQuestionReq: isChangeQuestionReq,
          });
        } else {
          remainInteractiveTime.current = res.remainInteractive;
          remainChangeQuestionTime.current = res.remainChangeQuestion;
          showBotsText(res.aiQuestion || res);
          if (remainChangeQuestionTime.current > 0) {
            showChangeQuestionButtons();
          }
          setInputDisabled(false);
          status.current = 1;
          userAnsQ.current = 1;
        }
      });
    }
  };

  const handleYouAskAi = () => {
    removeTarget(interactiveButtonsId);
    showBotsText(
      `${intl.formatMessage({
        id: "ChatProcess.openBrackets",
      })}${intl.formatMessage({
        id: STYLE === "1" ? "ChatProcess.youAskAi" : "ChatProcess.youAskAi2",
      })}${intl.formatMessage({
        id: "ChatProcess.closeBrackets",
      })}${intl.formatMessage({ id: "ChatProcess.enterQuestion2" })}`
    );
    setInputDisabled(false);
    status.current = 1;
    userAnsQ.current = 0;
  };

  const handleChangeQuestion = () => {
    if (remainChangeQuestionTime.current > 0) {
      setInputDisabled(true);
      remainChangeQuestionTime.current -= 1;
      removeTarget(changeQuestionButtonsId);
      handleAiAskYou(null, { isChangeQuestionReq: true });
    }
  };

  const getSessionId = (cb) => {
    getSession(langMap[LANG], abortController.current, (err, res) => {
      if (err) {
        showBotsText(err);
      } else {
        webSessionId.current = res;
        cb();
      }
    });
  };

  const closeTelegram = () => {
    setShowTelegram(false);
  };

  const updateCreditDisplay = () => {
    webCreditCount(
      langMap[LANG],
      { agentId: browserFingerPrint, append: true },
      abortController.current,
      async (error, resQ) => {
        if (error) {
          showBotsText(error);
        } else {
          if (resQ) {
            const remainCredits = resQ.credit.total;
            const remainFreeCredits = resQ.credit.freeCredits;
            setRemindCredits(remainFreeCredits);
          }
        }
      }
    );
  };

  const startProcess = () => {
    updateCreditDisplay();
    if (STYLE === "2") {
      showPic(`${process.env.PUBLIC_URL}imgs/mirror.jpg`);
    } else if (STYLE === "3") {
      showPic(`${process.env.PUBLIC_URL}/imgs/chat01.png`);
    }
    /* showBotsText(intl.formatMessage({ id: "ChatProcess.beginingText" })); */
    if (QUESTION === null) {
      if (isShowClassifyButton) {
        showClassifyButtons();
      } else {
        // actions for not showing classify buttons
        classify.current = "others";
        webCreditCount(
          langMap[LANG],
          { agentId: browserFingerPrint, append: true },
          abortController.current,
          async (error, resQ) => {
            if (error) {
              showBotsText(error);
            } else {
              if (resQ) {
                const remainCredits = resQ.credit.total;
                const remainFreeCredits = resQ.credit.freeCredits;
                setRemindCredits(remainFreeCredits);
                if (remainCredits > 0) {
                  if (remainFreeCredits <= 0) {
                    showCreditButtons(remainCredits);
                  } else {
                    handleUseCredit();
                  }
                } else {
                  handleUseCredit();
                }
              }
            }
          }
        );
      }
    } else {
      classify.current = QUESTION.classify;
      showBotsText(
        `${intl.formatMessage({
          id: "ChatProcess.categorySelected",
        })}${classifyMap[classify.current]}`
      );
      if (QUESTION.classify === "love") {
        gender.current = QUESTION.gender;
      }
      showYourText(QUESTION.q);
      webCreditCount(
        langMap[LANG],
        { agentId: browserFingerPrint, append: true },
        abortController.current,
        async (error, resQ) => {
          if (error) {
            showBotsText(error);
          } else {
            if (resQ) {
              const remainCredits = resQ.credit.total;
              const remainFreeCredits = resQ.credit.freeCredits;
              setRemindCredits(remainFreeCredits);
              if (remainCredits > 0) {
                if (remainFreeCredits <= 0) {
                  showCreditButtons(remainCredits);
                } else {
                  handleUseCredit();
                }
              } else {
                handleSend(null, { userText: QUESTION.q });
                //handleUseCredit();
              }
            }
          }
        }
      );
    }
  };

  useEffect(() => {
    const controller = abortController.current;
    const handleBackButton = () => {
      handleClose();
    };

    window.addEventListener("popstate", handleBackButton);

    getSessionId(() => {
      userLog(
        "enter_chat",
        userSign,
        "zh-TW",
        webSessionId.current,
        (err, res) => {
          startProcess();
        }
      );
    });
    return () => {
      window.removeEventListener("popstate", handleBackButton);
      controller.abort();
    };
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className={`${cssStyleName}-bg`}>
      {showTelegram && (
        <TelegramCode closeTelegram={closeTelegram} LANG={LANG} STYLE={STYLE} />
      )}
      {clientSecret && showPay && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            clientSecret={clientSecret}
            changeShowPay={changeShowPay}
            handleSuccessPay={handleSuccessPay}
          />
        </Elements>
      )}
      <div className={`${cssStyleName}-bgframe`}>
        {showCloseDialog && (
          <div className={`${cssStyleName}-closeDialog`}>
            <div className={`${cssStyleName}-closeDialog__content`}>
              {intl.formatMessage({ id: "ChatProcess.closeWarning" })}
              <div className={`${cssStyleName}-closeDialog__buttonFrame`}>
                <div
                  className={`${cssStyleName}-closeDialog__button`}
                  onClick={closeReturn}
                >
                  {intl.formatMessage({ id: "ChatProcess.closeReturn" })}
                </div>
                <div
                  className={`${cssStyleName}-closeDialog__button`}
                  onClick={confirmClose}
                >
                  {intl.formatMessage({ id: "ChatProcess.closeConfirm" })}
                </div>
              </div>
            </div>
          </div>
        )}
        {STYLE === "1" && (
          <>
            <img
              src={`${process.env.REACT_APP_IMG_URL}web_img/chatbg_pc.png`}
              alt=""
              className={`${cssStyleName}-bgframe_img ${cssStyleName}-bgframe_imgpc`}
            />

            <img
              src={`${process.env.REACT_APP_IMG_URL}web_img/process_mobile.png`}
              alt=""
              className={`${cssStyleName}-bgframe_img ${cssStyleName}-bgframe_imgmobile`}
            />
          </>
        )}
        {STYLE === "2" && (
          <>
            <img
              src={`${process.env.PUBLIC_URL}/imgs/logo2.png`}
              className={`${cssStyleName}-bgframe__img`}
              alt=""
            />
            {/* <img
              src={`${process.env.PUBLIC_URL}/imgs/bar_down.png`}
              className="ChatProcess-bgframe2__imgBottom"
            /> */}
          </>
        )}

        <div className={`${cssStyleName}-header`}>
          <div
            className={
              STYLE === "1"
                ? `${cssStyleName}-header_coin`
                : `${cssStyleName}-header_creditBox`
            }
          >
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/imgs/coin.png`}
              className={`${cssStyleName}-header_coin`}
            />
            {`${remindCreditsCount}${intl.formatMessage({
              id: "ChatProcess.preDay",
            })}`}
          </div>
          <div
            className={`${cssStyleName}-header_closeBtn`}
            onClick={handleClose}
          >
            {intl.formatMessage({ id: "ChatProcess.closeText" })}
          </div>
        </div>

        <div
          id="u_interactive"
          className={`${cssStyleName}-interactiveFrame`}
        ></div>

        <div
          id={inputFrameId}
          className={
            `${cssStyleName}-inputframe ` +
            (STYLE === "1"
              ? ""
              : inputDisabled
              ? "disabled ChatProcess-noShow"
              : "")
          }
        >
          <input
            id={inputTextId}
            type="text"
            className={`${cssStyleName}-inputframe2_input`}
            disabled={inputDisabled}
            /* placeholder={intl.formatMessage({ id: "ChatProcess.hintsText" })} */
          />
          <div
            className="ChatProcess-inputframe2_sendBtn"
            disabled={inputDisabled}
            onClick={handleSend}
          >
            {intl.formatMessage({ id: "ChatProcess.sendContent" })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatProcess;
